@import url('https://fonts.googleapis.com/css?family=Montserrat:800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.resume_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.resume_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(25, 63, 112);
    font-size: 72px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.content_container {
    max-width: 1200px;
}

p {
    font-size: 16px;
    line-height: 1.3;
}

.job_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 900;
    color: rgb(0, 0, 0);
    padding-top: 24px;
    padding-left: 48px;

}
.company_name {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 1;
    padding-left: 48px;
}
.dates_employed {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.employment_duration {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.location_style {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.job_description {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: rgb(25, 63, 112);
    padding-left: 48px;
    padding-right: 50px;
    font-style: italic;
}

.experience_item_a {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    background: rgb(245, 245, 245);
    padding: 24px 0;
    width: 100%;
}

.experience_item_b {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    padding: 24px 0;
    width: 100%;
}

.pdf_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
    padding: 12px 18px;
    margin: 12px;
    width: 84%;
}

.pdf_content_text {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    width:100%;
}
.pdf_download_button {
    display: block;
    margin: 12px 0;
    background-color: rgb(20, 175, 214);
    width: 200px;
    padding: 6px 30px;
    border-radius: 8px;
    border: 1px rgb(145, 139, 139) solid;
    color: white;
    font-size: 12px;
}

a {
    color: white;
    text-decoration: none;
}


/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .resume_section_title {
        font-size: 50px;
    }
    .content_container {
        max-width: 300px;
    }
    .job_title {
        font-size: 24px;
        padding-left: 24px;
        line-height: 1.1;
    }
    .company_name {
        font-size: 18px;
        padding-left: 24px;
    }
    .dates_employed {
        padding-left: 24px;
    }
    .employment_duration {
        padding-left: 24px;
    }
    .location_style {
        padding-left: 24px;
    }
    .job_description {
        padding-left: 24px;
        padding-right: 12px;
        font-style: italic;
    }
}
   