.summary_section {    
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    min-height: 800px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.summary_section_title {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 72px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.summary_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px;
    max-width: 1200px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 18px;
}

.summary_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 40px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.summary_content {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 24px;
    padding: 30px;
    line-height: 1.2;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 520px) and (min-width: 319px){  
    .summary_section_title {
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 46px;
        padding: 8px;
    }
    .summary_content_container {
        max-width: 300px;
    }
    .summary_content {
        font-family: 'Lato', sans-serif;
        color: white;
        font-size: 16px;
        padding: 18px;
        line-height: 1.2;
    }
}