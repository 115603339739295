@import url('https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap');

.footer_section {
    height: 400px;
    background-color: rgb(4, 36, 46);
}

.footer_title {
    font-family: 'Kanit', sans-serif;
    font-size: 64px;
    color: white;
    text-align: center;
}

.footer_content {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgb(165, 158, 158);
    text-align: center;
    padding: 24px;
}

.back_to_top {    
    text-align: center;    
    padding-top: 10px;  
}

.back_to_top {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: peru;
    text-decoration: underline;
    padding-top: 24px;
}