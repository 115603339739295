@import url('https://fonts.googleapis.com/css?family=Raleway|Yesteryear&display=swap');

.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    text-align: right;
    width: 100%;
    background-color: rgb(4, 36, 46);
    color: white;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 6px;
    height: 26px;
    z-index: 1;
}

.nav-links {
    font-family: 'Raleway', sans-serif;
    list-style: none;
    padding: 5px 10px 10px 10px;
}    

.nav-bar > .active {
    font-family: 'Raleway', sans-serif;
    color: peru;
    border-bottom: 2px solid white;    
}

.nav_logo {
    height: 24px;
    float: left;
    padding-left: 20px;
    margin-top: 0;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .nav-links {
        display: none;    
    }
}

@media screen and (max-width: 600px) and (min-width: 481px){  
    .nav-links {
        display: none;    
    }
}