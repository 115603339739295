@import url('https://fonts.googleapis.com/css?family=Montserrat:800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.home_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(37, 31, 31);
    margin-top: 40px;
}

.home_section_background {
    width: 100%;
    padding: 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.home_section_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    max-width: 1300px;
    margin: 0 auto;
    padding: 5% 0;
    border-radius: 18px;
    border: white 1px solid;
    background-color: rgba(43, 5, 16, 0.4)
}

.home_title {
    font-family: 'Montserrat', sans-serif;;
    color: rgb(255, 255, 255);
    font-size: 90px;
    line-height: 105%;
    text-align: center;
    width: 88%;
}

.home_subtitle {
    font-family: 'Lato', sans-serif;
    line-height: 1;
    color: white;
    font-size: 36px;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

#highlight {
    color: peru;
}



/* -------------------- DESKTOP / LAPTOP SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 1600px) and (min-width: 1261px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 80px;
        line-height: 100%;
    }
    
}

/* -------------------- LAPTOP / LARGE TABLET SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 1260px) and (min-width: 854px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 60px;
        line-height: 100%;
    }
    
}

/* -------------------- SMALL TABLET / LARGE MOBILE SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 854px) and (min-width: 481px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 34px;
        line-height: 100%;
    }
    .home_subtitle {
        line-height: 1;
        font-size: 20px;
        width: 100%;       
    }
    #highlight {
        color: peru;
        font-size: 20px;
        text-align: center;
        line-height: 105%;
    }
    
}

/* -------------------- SMALL MOBILE MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .home_section_content_container {
        padding: 8px;    
    }
    .home_section_background {
        width: 100%;
        padding: 15%;
    }
    .home_section_content_container {
        background-color: rgba(0, 0, 0, .5)
    }    
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 26px;
        line-height: 100%;
    }

    .home_subtitle {
        line-height: 1;
        font-size: 20px;
        width: 100%;       
    }

    #highlight {
        color: peru;
        font-size: 20px;
        text-align: center;
        line-height: 105%;
    }
}
