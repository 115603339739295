@import url(https://fonts.googleapis.com/css?family=Raleway|Yesteryear&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Kanit:700|Raleway:400,900&display=swap);
body {
  margin: 0;  
}





.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    text-align: right;
    width: 100%;
    background-color: rgb(4, 36, 46);
    color: white;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 6px;
    height: 26px;
    z-index: 1;
}

.nav-links {
    font-family: 'Raleway', sans-serif;
    list-style: none;
    padding: 5px 10px 10px 10px;
}    

.nav-bar > .active {
    font-family: 'Raleway', sans-serif;
    color: peru;
    border-bottom: 2px solid white;    
}

.nav_logo {
    height: 24px;
    float: left;
    padding-left: 20px;
    margin-top: 0;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .nav-links {
        display: none;    
    }
}

@media screen and (max-width: 600px) and (min-width: 481px){  
    .nav-links {
        display: none;    
    }
}
.home_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(37, 31, 31);
    margin-top: 40px;
}

.home_section_background {
    width: 100%;
    padding: 20%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.home_section_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    max-width: 1300px;
    margin: 0 auto;
    padding: 5% 0;
    border-radius: 18px;
    border: white 1px solid;
    background-color: rgba(43, 5, 16, 0.4)
}

.home_title {
    font-family: 'Montserrat', sans-serif;;
    color: rgb(255, 255, 255);
    font-size: 90px;
    line-height: 105%;
    text-align: center;
    width: 88%;
}

.home_subtitle {
    font-family: 'Lato', sans-serif;
    line-height: 1;
    color: white;
    font-size: 36px;
    width: 70%;
    text-align: center;
    margin: 0 auto;
}

#highlight {
    color: peru;
}



/* -------------------- DESKTOP / LAPTOP SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 1600px) and (min-width: 1261px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 80px;
        line-height: 100%;
    }
    
}

/* -------------------- LAPTOP / LARGE TABLET SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 1260px) and (min-width: 854px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 60px;
        line-height: 100%;
    }
    
}

/* -------------------- SMALL TABLET / LARGE MOBILE SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 854px) and (min-width: 481px) {   
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 34px;
        line-height: 100%;
    }
    .home_subtitle {
        line-height: 1;
        font-size: 20px;
        width: 100%;       
    }
    #highlight {
        color: peru;
        font-size: 20px;
        text-align: center;
        line-height: 105%;
    }
    
}

/* -------------------- SMALL MOBILE MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .home_section_content_container {
        padding: 8px;    
    }
    .home_section_background {
        width: 100%;
        padding: 15%;
    }
    .home_section_content_container {
        background-color: rgba(0, 0, 0, .5)
    }    
    .home_title {        
        color: rgb(255, 255, 255);
        font-size: 26px;
        line-height: 100%;
    }

    .home_subtitle {
        line-height: 1;
        font-size: 20px;
        width: 100%;       
    }

    #highlight {
        color: peru;
        font-size: 20px;
        text-align: center;
        line-height: 105%;
    }
}

.skills_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.skills_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 72px;
    padding: 30px 30px 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.skills_content {
    font-family: 'Lato', sans-serif;
    color: rgb(255, 255, 255);
    padding: 30px;
    max-width: 1200px;
}

.skills_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(25, 63, 112);
    font-size: 40px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.skills_columns_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px 24px 120px;
    max-width: 1200px;
}

.skills_column_box {
    width: 376px;
	background-color: rgb(38, 4, 46);
	border-radius: 9px;
	padding-bottom: 12px;
	padding-top: 12px;
	margin: 4px;	
}

.skills_column_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: white;
    padding: 24px 24px 12px;
}

.skills_column_list {
    font-family: 'Lato', sans-serif;
    color: white;
    margin: 4px 4px 18px;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .skills_section_title {
        font-family: 'Montserrat', sans-serif;
        color: rgb(38, 4, 46);
        font-size: 50px; 
        padding-bottom: 0px;       
    }

    .skills_columns_container {
        max-width: 300px;
    }

    .skills_column_box {
        background-color: rgb(38, 4, 46);
        border-radius: 9px;
        margin: 8px;	
    }
}
.summary_section {    
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    min-height: 800px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.summary_section_title {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 72px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.summary_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px;
    max-width: 1200px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 18px;
}

.summary_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 40px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.summary_content {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 24px;
    padding: 30px;
    line-height: 1.2;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 520px) and (min-width: 319px){  
    .summary_section_title {
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 46px;
        padding: 8px;
    }
    .summary_content_container {
        max-width: 300px;
    }
    .summary_content {
        font-family: 'Lato', sans-serif;
        color: white;
        font-size: 16px;
        padding: 18px;
        line-height: 1.2;
    }
}
.resume_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.resume_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(25, 63, 112);
    font-size: 72px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.content_container {
    max-width: 1200px;
}

p {
    font-size: 16px;
    line-height: 1.3;
}

.job_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 32px;
    font-weight: 900;
    color: rgb(0, 0, 0);
    padding-top: 24px;
    padding-left: 48px;

}
.company_name {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 1;
    padding-left: 48px;
}
.dates_employed {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.employment_duration {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.location_style {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: rgb(25, 63, 112);
    padding-left: 48px;
}
.job_description {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: rgb(25, 63, 112);
    padding-left: 48px;
    padding-right: 50px;
    font-style: italic;
}

.experience_item_a {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    background: rgb(245, 245, 245);
    padding: 24px 0;
    width: 100%;
}

.experience_item_b {
    display: flex;
    flex-direction: wrap;
    justify-content: center;
    padding: 24px 0;
    width: 100%;
}

.pdf_content_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
    padding: 12px 18px;
    margin: 12px;
    width: 84%;
}

.pdf_content_text {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    width:100%;
}
.pdf_download_button {
    display: block;
    margin: 12px 0;
    background-color: rgb(20, 175, 214);
    width: 200px;
    padding: 6px 30px;
    border-radius: 8px;
    border: 1px rgb(145, 139, 139) solid;
    color: white;
    font-size: 12px;
}

a {
    color: white;
    text-decoration: none;
}


/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .resume_section_title {
        font-size: 50px;
    }
    .content_container {
        max-width: 300px;
    }
    .job_title {
        font-size: 24px;
        padding-left: 24px;
        line-height: 1.1;
    }
    .company_name {
        font-size: 18px;
        padding-left: 24px;
    }
    .dates_employed {
        padding-left: 24px;
    }
    .employment_duration {
        padding-left: 24px;
    }
    .location_style {
        padding-left: 24px;
    }
    .job_description {
        padding-left: 24px;
        padding-right: 12px;
        font-style: italic;
    }
}
   
.portfolio_section {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.portfolio_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 72px;
    padding-top: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.portfolio_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 24px;
    padding: 15px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.portfolio_card {
    background-color: rgba(19, 2, 24, 0.9);
    border: 1px solid white;
    border-radius: 18px;
    margin: 24px 12px;
    max-width: 550px;
    height: 670px;
}

.portfolio_card_title {
    font-family: 'Montserrat', sans-serif;;
    color: white;
    font-size: 30px;
    line-height: 1.2;
    color: white;
    margin: 24px
}

.portfolio_content {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.2;
    margin: 24px;
}

.portfolio_box_image {
    margin: 24px;
    max-width: 480px;
    border: rgb(12, 12, 12) 2px solid;
    border-radius: 12px;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 580px) and (min-width: 319px){  
    
    .portfolio_section_title {
        font-size: 46px;
    }
    .portfolio_section_subtitle {
        font-size: 16px;
    }
    .portfolio_card {
        max-width: 95%;
        height: 600px;
        margin: 4px;
    }
    .portfolio_box_image {
        margin: 6px;
        padding: 8px;
        max-width: 90%;
    }

}
.contact_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);
}

.contact_section_background {
    width: 100%;
    padding: 10%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.contact_section_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    max-width: 80%;
    margin: 0 auto;
    padding: 20% 0;
    border-radius: 18px;
    background-color: rgba(0, 0, 0, .7)
}

.contact_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 70px;
    line-height: 90%;
    text-align: center;
    max-width: 90%; 
}

.contact_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 90%;
    text-align: center;
    max-width: 80%;
    margin-top: 34px;
}

.contact_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
    padding-top: 12px;
    padding-bottom: 20px;
    text-align: center;
}

.contact_button {
    display: block;
    margin: 0 auto;
    background-color: rgb(20, 175, 214);
    width: 100%;
    padding: 6px 30px;
    border-radius: 8px;
    border: 1px white solid;
    color: white;
    font-size: 12px;
}

a {
    color: white;
    text-decoration: none;
}

.linked_in {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    font-family: 'Raleway', sans-serif;
    border: white 1px solid;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 8px;
    padding: 12px;
}

.logo_image {
    width: 80px;
    margin: 0 auto;
}


@media screen and (max-width: 480px) and (min-width: 319px) {
    
    .contact_section_content_container {
        padding-top: 10px;
        padding-left: 5px;  
        padding-right: 5px;  
        padding-bottom: 10px;
        max-width: 90%;
    }
    
    .contact_section_background {
        width: 100%;
        padding: 5%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .contact_title {
        padding-top: 12px;
        font-size: 32px;
        line-height: 100%;
    }

    .contact_subtitle {
        font-size: 26px;
        line-height: 90%;
        max-width: 80%;
        margin-top: 34px;
    }

    .contact_content {
        font-size: 16px;
        width: 90%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .contact_button {
        width: 100%;
        margin: 12px 0;
    }
  
}
.footer_section {
    height: 400px;
    background-color: rgb(4, 36, 46);
}

.footer_title {
    font-family: 'Kanit', sans-serif;
    font-size: 64px;
    color: white;
    text-align: center;
}

.footer_content {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: rgb(165, 158, 158);
    text-align: center;
    padding: 24px;
}

.back_to_top {    
    text-align: center;    
    padding-top: 10px;  
}

.back_to_top {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: peru;
    text-decoration: underline;
    padding-top: 24px;
}
