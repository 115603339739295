@import url('https://fonts.googleapis.com/css?family=Montserrat:800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.contact_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);
}

.contact_section_background {
    width: 100%;
    padding: 10%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}


.contact_section_content_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    max-width: 80%;
    margin: 0 auto;
    padding: 20% 0;
    border-radius: 18px;
    background-color: rgba(0, 0, 0, .7)
}

.contact_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 70px;
    line-height: 90%;
    text-align: center;
    max-width: 90%; 
}

.contact_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 90%;
    text-align: center;
    max-width: 80%;
    margin-top: 34px;
}

.contact_content {
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 18px;
    width: 60%;
    margin: 0 auto;
    padding-top: 12px;
    padding-bottom: 20px;
    text-align: center;
}

.contact_button {
    display: block;
    margin: 0 auto;
    background-color: rgb(20, 175, 214);
    width: 100%;
    padding: 6px 30px;
    border-radius: 8px;
    border: 1px white solid;
    color: white;
    font-size: 12px;
}

a {
    color: white;
    text-decoration: none;
}

.linked_in {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    align-content: center;
    font-family: 'Raleway', sans-serif;
    border: white 1px solid;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    margin: 8px;
    padding: 12px;
}

.logo_image {
    width: 80px;
    margin: 0 auto;
}


@media screen and (max-width: 480px) and (min-width: 319px) {
    
    .contact_section_content_container {
        padding-top: 10px;
        padding-left: 5px;  
        padding-right: 5px;  
        padding-bottom: 10px;
        max-width: 90%;
    }
    
    .contact_section_background {
        width: 100%;
        padding: 5%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .contact_title {
        padding-top: 12px;
        font-size: 32px;
        line-height: 100%;
    }

    .contact_subtitle {
        font-size: 26px;
        line-height: 90%;
        max-width: 80%;
        margin-top: 34px;
    }

    .contact_content {
        font-size: 16px;
        width: 90%;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .contact_button {
        width: 100%;
        margin: 12px 0;
    }
  
}