@import url('https://fonts.googleapis.com/css?family=Montserrat:800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.portfolio_section {    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.portfolio_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 72px;
    padding-top: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.portfolio_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 24px;
    padding: 15px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.portfolio_card {
    background-color: rgba(19, 2, 24, 0.9);
    border: 1px solid white;
    border-radius: 18px;
    margin: 24px 12px;
    max-width: 550px;
    height: 670px;
}

.portfolio_card_title {
    font-family: 'Montserrat', sans-serif;;
    color: white;
    font-size: 30px;
    line-height: 1.2;
    color: white;
    margin: 24px
}

.portfolio_content {
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 16px;
    line-height: 1.2;
    margin: 24px;
}

.portfolio_box_image {
    margin: 24px;
    max-width: 480px;
    border: rgb(12, 12, 12) 2px solid;
    border-radius: 12px;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 580px) and (min-width: 319px){  
    
    .portfolio_section_title {
        font-size: 46px;
    }
    .portfolio_section_subtitle {
        font-size: 16px;
    }
    .portfolio_card {
        max-width: 95%;
        height: 600px;
        margin: 4px;
    }
    .portfolio_box_image {
        margin: 6px;
        padding: 8px;
        max-width: 90%;
    }

}