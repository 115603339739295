@import url('https://fonts.googleapis.com/css?family=Montserrat:800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

.skills_section {    
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.skills_section_title {
    font-family: 'Montserrat', sans-serif;
    color: rgb(38, 4, 46);
    font-size: 72px;
    padding: 30px 30px 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.skills_content {
    font-family: 'Lato', sans-serif;
    color: rgb(255, 255, 255);
    padding: 30px;
    max-width: 1200px;
}

.skills_section_subtitle {
    font-family: 'Montserrat', sans-serif;
    color: rgb(25, 63, 112);
    font-size: 40px;
    padding: 30px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.skills_columns_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    margin: 24px 24px 120px;
    max-width: 1200px;
}

.skills_column_box {
    width: 376px;
	background-color: rgb(38, 4, 46);
	border-radius: 9px;
	padding-bottom: 12px;
	padding-top: 12px;
	margin: 4px;	
}

.skills_column_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: white;
    padding: 24px 24px 12px;
}

.skills_column_list {
    font-family: 'Lato', sans-serif;
    color: white;
    margin: 4px 4px 18px;
}

/* -------------------- SMALL SCREEN MEDIAQUERY ------------- */
@media screen and (max-width: 480px) and (min-width: 319px){  
    .skills_section_title {
        font-family: 'Montserrat', sans-serif;
        color: rgb(38, 4, 46);
        font-size: 50px; 
        padding-bottom: 0px;       
    }

    .skills_columns_container {
        max-width: 300px;
    }

    .skills_column_box {
        background-color: rgb(38, 4, 46);
        border-radius: 9px;
        margin: 8px;	
    }
}